const quiSommeNous = () => {

    // Hero
    let hero = document.querySelector('.hero');
    if (hero) {
        const heroTl = gsap.timeline({
            defaults: {
                duration: 1,
                stagger: .2,
                ease: 'power2.out'
            }
        });

        heroTl
        .from('.hero__content--h1', {
            y: 40,
            opacity: 0
        })
        .from('.hero__content--h1-primary', {
            y: 60,
        }, '<.3')
        .from('.hero__content--baseline', {
            y: 40,
            opacity: 0
        }, '<.3')
        .to('.hero__media--layer', {
            width: '100%'
        }, '<')
        .to('.hero__media--content', {
            opacity: 1
        })
        .to('.hero__media--layer', {
            left: '100%'
        }, '<.3')
        .from('.hero__pattern', {
            y: 40,
            opacity: 0
        }, '<')
    }
    


    // Réassurance
    let reassurance = document.querySelector('.reassurance');
    if (reassurance) {
        const reassuranceTl = gsap.timeline({
            scrollTrigger: {
                trigger: reassurance,
                start: '0 30%'
            }
        })

        reassuranceTl
        .from('.reassurance__media', {
            opacity: 0,
            scale: .9,
            ease: 'power1.out',
            duration: 1
        })
        .from('.reassurance__content--icon div', {
            y: 50,
            ease: 'power1.out',
            duration: .6
        }, '>')
        .from('.reassurance__content h2, .reassurance__content p', {
            y: 20,
            opacity: 0,
            ease: 'power2.out',
            duration: 1,
            stagger: .2
        }, '>')
        .from('.reassurance__content--milestone span', {
            y: 30,
            ease: 'power2.out',
            duration: .6,
            stagger: .15
        }, '>')
        .from('.reassurance__content--milestone dt', {
            y: 30,
            opacity: 0,
            ease: 'power2.out',
            duration: .6,
            stagger: .15
        })
    }
    


    // Team
    let team = document.querySelector('.team');
    if (team) {
        
        const teamTl = gsap.timeline({
            defaults: {
                stagger: .1,
                duration: .6
            },
            scrollTrigger: {
                trigger: team,
                start: '0 60%'
            }
        })

        teamTl
        .from('.team__employee--job h3 span', {
            y: 40,
            duration: 1,
            ease: 'power1.out'
        })
        .from('.team__employee--image-layer, .team__employee--job-layer', {
            width: 0,
            duration: 1,
            ease: 'power1.out'
        }, '<.1')
        .to('.team__employee--image img, .team__employee--job-layer + p', {
            opacity: 1
        })
        .to('.team__employee--image-layer, .team__employee--job-layer', {
            left: '100%',
            ease: 'power1.in'
        }, '<.1')
        .from('.team__employee--jobContent', {
            y: 20,
            opacity: 0,
            duration: 1,
            stagger: .2,
            ease: 'power1.out'
        }, '<.1')
    }




    // Quote
    let quote = document.querySelector('.quote')
    if (quote) {

        const quoteTl = gsap.timeline({
            defaults: {

            },
            scrollTrigger: {
                trigger: quote,
                start: '0 70%'
            }
        })

        quoteTl
        .from('.quote h3 span', {
            y: 60,
            duration: 1,
            ease: 'power1.out'
        })
        .from('.quote__image', {
            scale: .9,
            opacity: 0,
            duration: 1,
            ease: 'power1.out'
        })
        .from('.quote__content > *', {
            y: 20,
            opacity: 0,
            duration: .5,
            stagger: .15,
            ease: 'power1.out'
        }, '<')

    } 

}

export default quiSommeNous;