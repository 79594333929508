const commonAnimations = () => {

    const bodyClasses = document.body.classList



    // Hero service
    let heroHalf = document.querySelector('.heroHalf')
    if (heroHalf) {
        const heroHalfTl = gsap.timeline({
            scrollTrigger: {
                trigger: heroHalf,
                start: '0 70%',
            }
        });
        heroHalfTl
            .from('.heroHalf__content > *', {
                y: 50,
                opacity: 0,
                duration: .7,
                stagger: .5
            }, 0)
            .from('.heroHalf__image .heroHalf__image--container', {
                rotate: '25deg',
                duration: 2.3
            }, 0)
            .from('.heroHalf__image .heroHalf__image--container img', {
                rotate: '-25deg',
                y: 250,
                opacity: 0,
                duration: 2.3
            }, 0)
    }



    // Milestones
    let milestones = document.querySelector('.milestones');
    if (milestones) {
        const milestonesTl = gsap.timeline({
            scrollTrigger: {
                trigger: milestones,
                start: '0 85%'
            }
        });
        gsap.utils.toArray(".milestone").forEach(milestone => {

            milestonesTl.from(milestone.querySelectorAll(".milestone__icon div"), {
                y: 50,
                opacity: 0.5,
                duration: 0.5,
            }, '<.4');

            milestonesTl.from(milestone.querySelectorAll(".milestone__title div"), {
                y: 25,
                duration: .75,
                ease: 'power1.out',
            }, '<');

            milestonesTl.from(milestone.querySelectorAll(".milestone__content"), {
                y: 25,
                opacity: 0,
                duration: 1,
                ease: 'power1.out',
            }, '<');

        });
    }




    // (target all h2's)
    gsap.utils.toArray('h2').forEach(h2 => {
        gsap.from(h2, {
            scrollTrigger: h2,
            transform: "rotateY(20deg) rotateZ(-4deg)",
            opacity: 0,
            duration: 1
        })
    });



    // Service cards
    let servicesContainer = document.querySelector('.services__top');
    if (servicesContainer) {
        const servicesTl = gsap.timeline({
            ease: 'power1.out',
            scrollTrigger: {
                trigger: servicesContainer,
                start: '0 40%',
            }
        });
        servicesTl
            .from(document.querySelectorAll('.service__top'), {
                y: 100,
                opacity: 0,
                duration: 1.5,
                stagger: .5
            })
            .from(document.querySelectorAll('.service__top--content'), {
                opacity: 0,
                y: 40,
                duration: 1,
                stagger: .5
            }, '-=1.5')
    }




    // Société
    let societe = document.querySelector('.societe')
    if (societe) {
        const societeTl = gsap.timeline({
            scrollTrigger: {
                trigger: societe,
                start: '0 70%',
            }
        });
        societeTl
            .from('.societe__content > div', {
                y: 50,
                opacity: 0,
                duration: .7,
                stagger: .5
            }, 0)
            .from('.societe__image .societe__image--container', {
                rotate: '25deg',
                duration: 1.8
            }, 0)
            .from('.societe__image .societe__image--container img', {
                rotate: '-25deg',
                y: 150,
                opacity: 0,
                duration: 1.8
            }, 0)
    }







    // Certifications
    let certifications = document.querySelector('.certifications')
    if (certifications) {
        const certificationsTl = gsap.timeline({
            scrollTrigger: {
                trigger: certifications,
                start: '0 70%',
            }
        })

        certificationsTl
            .from('.certifications .certifications__content', {
                opacity: 0,
                y: 50,
                duration: .7,
                stagger: .5
            }, 0)
            .from('.certifications .certifications__images img', {
                opacity: 0,
                scale: .75,
                stagger: .05,
                duration: .5,
            }, '+=.5')
    }






    // Références
    let references = document.querySelector('.references')
    if (references) {
        const referencesTl = gsap.timeline({
            scrollTrigger: {
                trigger: references,
                start: '0 70%',
            }
        })

        referencesTl
            .from('.references .references__title span', {
                y: 30,
                duration: 1,
            })
            .from('.references .references__images img', {
                opacity: 0,
                scale: .75,
                stagger: .05,
                duration: .5,
            })
    }






    // Avis
    let avis = document.querySelector('.avis')
    if (avis) {
        const avisTl = gsap.timeline({
            scrollTrigger: {
                trigger: avis,
                start: '0 60%'
            }
        })

        if (bodyClasses.contains('avis__elfsight')) {

            avisTl
                .from('.avis__container--elfsight', {
                    y: 50,
                    opacity: 0,
                    duration: 1,
                })

        } else if (bodyClasses.contains('avis__statique')) {

            avisTl
                .from('.avis__container--statique .avis__card', {
                    y: 50,
                    opacity: 0,
                    duration: 1,
                    stagger: .5
                })

        }
    }





    // Chiffres
    let chiffres = document.querySelector('.chiffres')
    if (chiffres) {
        const chiffresTl = gsap.timeline({
            scrollTrigger: {
                trigger: chiffres,
                start: '0 70%'
            }
        })

        chiffresTl
            .from('.chiffres__content p', {
                ease: 'power2.out',
                y: 50,
                opacity: 0,
                duration: 1,
                stagger: .3
            })
            .to('.chiffres__content--mask', {
                width: '100%',
                duration: .5,
                stagger: .1
            })
            .to('.chiffres__content--mask + span', {
                opacity: 1
            })
            .to('.chiffres__content--mask', {
                marginTop: 40,
                duration: .5,
                stagger: .1
            }, '-=.6')
            .from('.chiffres__content--text', {
                y: 50,
                opacity: 0,
                duration: 1,
                stagger: .2
            }, '>-1')
    }





    // Siblings
    let siblings = document.querySelector('.siblings');
    if (siblings) {
        const siblingsTl = gsap.timeline({
            defaults: {
                ease: 'power2.out',
                stagger: .3
            },
            scrollTrigger: {
                trigger: siblings,
                start: '0 70%'
            }
        })

        siblingsTl
            .from('.sibling', {
                y: 40,
                opacity: 0,
                duration: 1
            })
            .from('.sibling__content > div h3, .sibling__content blockquote > *', {
                y: 40,
                opacity: 0,
                duration: .6
            }, '<')
    }





    // CTA
    let cta = document.querySelector('.cta');
    if (cta) {
        const ctaTl = gsap.timeline({
            scrollTrigger: {
                trigger: cta,
                start: '0 70%'
            }
        })

        ctaTl
            .from('.cta__content p, .cta__content a', {
                y: 40,
                opacity: 0,
                duration: .7,
                stagger: .3
            })
    }






    // Header
    let header = document.querySelector('.header');
    gsap
        .from(header, {
            y: -80,
            opacity: 0,
            duration: .6,
            ease: 'power2.out'
        })





    // Footer
    let footer = document.querySelector('.footer');
    const footerTl = gsap.timeline({
        scrollTrigger: {
            trigger: footer,
            start: '0 70%'
        }
    })

    footerTl
        .from('.footer__content > div', {
            y: 50,
            opacity: 0,
            duration: .6,
            stagger: .4,
            ease: 'power2.out'
        })
        .from('.footer__sub > *', {
            y: 50,
            duration: 1,
            ease: 'power2.out'
        }, '-=1')





    // Blog
    let blog = document.querySelector('.blog');
    if (blog) {
        const blogTl = gsap.timeline({
            scrollTrigger: {
                trigger: blog,
                start: '0 70%',
            }
        })

        blogTl
            .from('.blog__header p', {
                y: 40,
                opacity: 0,
                duration: .7
            })
            .from('.blog__post', {
                y: 50,
                opacity: 0,
                duration: 1,
                stagger: .3
            }, 0)
    }


    // Page
    let page = document.querySelector('.singlePage');
    if (page) {
        const pageTl = gsap.timeline({
            scrollTrigger: {
                trigger: page,
                start: '0 70%', 
            }
        })

        pageTl
            .from('.singlePage__content > *', {
                y: 20,
                opacity: 0,
                ease: 'power1.out',
                stagger: .05
            })
    }

}

export default commonAnimations;