const agences = () => {

    let agences = document.querySelector('.agencies');

    if (agences) {
        gsap.from('.hero__content h1 span', {
            y: 70,
            duration: 1.5,
            ease: 'power2.out'
        })
        gsap.from('.hero__content p', {
            y: 40,
            opacity: 0,
            duration: 1,
            ease: 'power2.out',
            delay: .4
        })
        gsap.utils.toArray('.agency__country').forEach(agence => {
            const agencesTl = gsap.timeline({
                defaults: {
                    ease: 'power2.out',
                    duration: .7
                },
                scrollTrigger: {
                    trigger: agence,
                    start: '0 70%'
                }
            });

            agencesTl
                .from(agence.querySelectorAll('.agency__city h3 span'), {
                    y: 40,
                }, 0)
                .from(agence.querySelectorAll('.agency__city > div > *'), {
                    y: 40,
                    opacity: 0,
                    stagger: .1
                }, '<')

        })
        
    }
    

}

export default agences;